import Vue from "vue";
import axios from "axios";

import { Notification, MessageBox } from "element-ui";

// 第三方插件
import "element-ui/lib/theme-chalk/index.css";

Vue.prototype.$axios = axios;
// 字体图标
const service = axios.create({
	baseURL: process.env.NODE_ENV === 'production' ? 'https://workflow.xiaowuxiongdi.com/api' : "http://172.16.24.200:9998",
	timeout: 50000
});

service.defaults.withCredentials = true; // 让ajax携带cookie
service.interceptors.request.use(
	// 每次请求都自动携带Cookie
	config => {
		//config.headers.Cookie = document.cookie
		const accessToken = localStorage.getItem('accessToken')

		// If token is present add it to request's Authorization Header
		if (accessToken) {
			// eslint-disable-next-line no-param-reassign
			// config.headers['ACCESS-TOKEN'] = accessToken
			config.headers['Authorization'] = `Bearer ${accessToken}`
		}

		return config;
	},
	// eslint-disable-next-line handle-callback-err
	error => {
		return Promise.reject(error);
	}
);

service.interceptors.response.use(
	rsp => {

		return rsp;
	},
	// 拦截异常的响应
	err => {
		console.log("请求", err.response);
		if (err.response.data.message.indexOf('token')) {
			window.location.href = '/login'
		}
		switch (err.response.status) {
			case 400:
				localStorage.removeItem('accessToken')
        window.location.href = '/login'
				break;
			case 401:
				MessageBox.alert("登陆已过期，请关闭当前窗口重新进入-能臣工作台");
				break;
			case 403:
				//Message.warning("抱歉，您无权访问！")
				break;
			case 500:
				Notification.error({ title: "提醒", message: "服务器出了点小错误" });
				break;
			case 404:
				Notification({
					title: "提醒",
					message: "未找到，检查参数",
					type: "warning"
				});
				break;
			default:
				//throw 'request error'
				return Promise.reject(err);
		}
		//throw 'request error'
		return Promise.reject(err);
	}
);

export default service;
